import { TodoItem, TodoOperationApplication, TodoFileSerializer } from '../lib/todotxtformat';

export { TodoItem };

export interface UserSettingsState {
  todoFilePath: string;
  useVirtualScroll: boolean;
}

export interface UserState {
  name: string;
  token: string;
  settings: UserSettingsState;
}

export interface AppliedTodoOperationSet {
  localRevisionId: string;
  remoteRevisionId?: string;
  localTime: number;
  operationApplications: TodoOperationApplication[];
}

export interface SynchronizationState {
  inProgress: boolean;
  syncId: string;
  localRevisionId: string;
  remoteRevisionId: string;
  lastCompleteTime: number;
  todos: TodoItem[];
  lastSyncedTodoFileContent: string;
}

export interface AppViewState {
  lastUsedFilterRoute: string;
}

export interface AppState {
  schemaVersion: number;
  list: TodoItem[];
  user?: UserState;
  router?: any;
  history: AppliedTodoOperationSet[];
  synchronization: SynchronizationState;
  view: AppViewState;
}

/*
# Mutating the list
Action is a list of ops => store these in a list for undo?
something to transform a list of ops into a list of string insert/deletes (also their inverse for undo?)
opgroup: listop[], strop[], localversion, remoteversion
listopgroup: listop[], localVersion: string
listop: enum type,
strop: type: 'insert' | 'delete', pos: int, val: str
'ComputeTransform' listop[] => strop[] + TodosCollection

*/

export const INITIAL_USER_SETTINGS: UserSettingsState = {
  useVirtualScroll: false,
  todoFilePath: '/todo/todo.txt'
};

export const INITIAL_STATE: AppState = {
  /*
  text file : string
  archive file: string
  parsed objects
    [todo]
  oplog[opgroup]
    opgroup[op]

   */
  schemaVersion: 2,
  list: [],
  user: null,
  history: [],
  synchronization: {
    inProgress: false,
    syncId: '',
    localRevisionId: null,
    remoteRevisionId: null,
    todos: null,
    lastCompleteTime: 0,
    lastSyncedTodoFileContent: null
  },
  view: {
    lastUsedFilterRoute: '/list'
  }
};
