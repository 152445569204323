const baseEnvironment = {
  appTitle: 'txtodo',
  apiUrl: 'https://txtodo-win-eastus.azurewebsites.net',
  dropbox: {
    clientId: 'b2upwtx37sfzr0q'
  }
};

const environments = {
  dev: {
    ...baseEnvironment,
    production: false,
    ouathStatePrefix: 'dev',
    useServiceWorker: false
  },
  ci: {
    ...baseEnvironment,
    appTitle: 'txtodo-ci',
    production: true,
    ouathStatePrefix: 'ci',
    useServiceWorker: true
  },
  prod: {
    ...baseEnvironment,
    production: true,
    ouathStatePrefix: 'prod',
    useServiceWorker: true
  }
};

let env = null;

if (!env) {
  const win = (window as any);
  if (win) {
    const loc = win.location;

    if (loc) {
      if (loc.hostname === 'txto.do') {
        env = environments.prod;
      } else if (loc.hostname === 'ci.txto.do') {
        env = environments.ci;
      }
    }
  }

  if (!env) {
    env = environments.dev;
  }
}

export const configuration = env;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
