import { Component, Input, OnInit, OnDestroy, forwardRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IonInput } from '@ionic/angular';

class SelectableOption {
  name: string;
  selected: boolean;
}

@Component({
  selector: 'author-tag-multi-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuthorTagMultiSelectComponent),
      multi: true
    }
  ],
  styles: [`
  ion-chip {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 1.1rem;
  }
  ion-icon.addOption{
    margin-left: 0px;
    margin-right: 0px;
   }
   ion-chip span.addNewInput {
    display: none;
   }
   ion-chip.addingNew span.addNewInput {
     display: inline;
   }
   ion-chip.addingNew span.addNewButton {
    display: none;
  }
  ion-chip ion-input {
    width: 100px;
  }
   `],
  template: `
  <div>
    <ion-chip *ngFor="let option of selectableOptions$ | async; trackBy:getOptionName"
      outline="true" [color]="option.selected ? 'primary' : ''"
      (click)="onToggleClick(option.name)">

      <ion-label>{{option.name}}</ion-label>
    </ion-chip>

    <ion-chip outline="true" (click)="addNewOption()"  [ngClass]="{ addingNew: addingNew$ | async }">
      <span class="addNewButton"><ion-icon name="add" class="addOption"></ion-icon></span>
      <span class="addNewInput"><ion-input #newTagInput
        (ionBlur)="newTagInputBlur()" (keydown.enter)="newTagInputEnterPress($event)"></ion-input></span>
    </ion-chip>
  </div>`
})
export class AuthorTagMultiSelectComponent implements ControlValueAccessor, OnInit {
  @Input()
  options: Observable<string[]>;

  @Input()
  set selected(selected: string[]) {
    if (!selected) {
      this.selected$.next([]);
    } else {
      this.selected$.next(selected);
    }
  }
  get selected(): string[] { return this.selected$.getValue(); }

  selected$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  novelOptions$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  selectableOptions$: Observable<SelectableOption[]>;

  addingNew$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('newTagInput') newTagInput: IonInput;

  private _changeListener: any = null;
  private _blurListener: any = null;

  ngOnInit(): void {
    this.selectableOptions$ = combineLatest(this.options, this.novelOptions$, this.selected$).pipe(
      map(([options, novelOptions, selected]) =>
        options.concat(novelOptions).map(opt => ({ name: opt, selected: selected.some(s => s === opt) })))
    );
  }

  addNewOption() {
    this.addingNew$.next(true);
    setTimeout(() => this.newTagInput.setFocus(), 200);
  }

  newTagInputBlur() {
    const option = this.newTagInput.value;
    if (option) {
      const previous = this.novelOptions$.getValue();
      // TODO if selectableTags already contains option, just select it
      this.novelOptions$.next(previous.concat(option));
      this.onToggleClick(option);
    }

    this.addingNew$.next(false);
    this.newTagInput.value = '';
  }

  newTagInputEnterPress(event) {
    this.newTagInput.getInputElement().then(el =>
      el.blur());
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }
  registerOnChange(fn: any): void {
    this._changeListener = fn;
  }
  registerOnTouched(fn: any): void {
    this._blurListener = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  onToggleClick(option: string) {
    const selected = this.selected$.getValue();
    if (this.selected.some(s => s === option)) {
      this.selected$.next(selected.filter(t => t !== option));
    } else {
      this.selected$.next(selected.concat(option));
    }

    const changeListener = this._changeListener;
    if (changeListener) {
      changeListener(this.selected);
    }
    const blurListener = this._blurListener;
    if (blurListener) {
      blurListener();
    }
  }

  getOptionName(_, option: SelectableOption) {
    return option.name;
  }
}
