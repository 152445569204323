/// <reference path="../../node_modules/@types/applicationinsights-js/index.d.ts" />

let appInsights = <Microsoft.ApplicationInsights.IAppInsights>window['appInsights'];

if (!appInsights) {
  appInsights = <Microsoft.ApplicationInsights.IAppInsights><unknown>{
    trackTrace: () => { },
    trackPageView: () => { },
    trackException: () => { },
  };
}

export const AppInsights = appInsights;
