import { TodoItem } from './model';

export const sortTodos = (todoA: TodoItem, todoB: TodoItem) => {
  if (todoA.isCompleted !== todoB.isCompleted) {
    if (!todoA.isCompleted) { return -1; }
    if (!todoB.isCompleted) { return 1; }
  }

  if (todoA.priority !== todoB.priority) {
    if (todoA.priority == null) { return -1; }
    if (todoB.priority == null) { return 1; }
    return todoA.priority.toUpperCase().charCodeAt(0) - todoB.priority.toUpperCase().charCodeAt(0);
  }
  return todoA.lineNumber - todoB.lineNumber;
};
