
  <div>
    <ion-chip *ngFor="let option of selectableOptions$ | async; trackBy:getOptionName"
      outline="true" [color]="option.selected ? 'primary' : ''"
      (click)="onToggleClick(option.name)">

      <ion-label>{{option.name}}</ion-label>
    </ion-chip>

    <ion-chip outline="true" (click)="addNewOption()"  [ngClass]="{ addingNew: addingNew$ | async }">
      <span class="addNewButton"><ion-icon name="add" class="addOption"></ion-icon></span>
      <span class="addNewInput"><ion-input #newTagInput
        (ionBlur)="newTagInputBlur()" (keydown.enter)="newTagInputEnterPress($event)"></ion-input></span>
    </ion-chip>
  </div>