import { Injectable, ErrorHandler } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
// import { Router, ActivatedRoute, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
// import { filter } from 'rxjs/operators';
// import { Subscription } from 'rxjs';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
  handleError(error: any): void {
    AppInsights.trackException(error);
    super.handleError(error);
  }
}

// @Injectable()
// export class RouterMonitoring {
//   routerSubscription: Subscription;

//   constructor(
//     private router: Router) {
//     this.routerSubscription = this.router.events
//       .pipe(filter(event => event instanceof ResolveEnd))
//       .subscribe((event: ResolveEnd) => {
//         const activatedComponent = this.getActivatedComponent(event.state.root);
//         if (activatedComponent) {
//           AppInsights.trackPageView(
//             `${activatedComponent.name}`,
//             this.getRouteTemplate(event.state.root)/*, event.urlAfterRedirects*/);
//         }
//       });
//   }

//   private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
//     if (snapshot.firstChild) {
//       return this.getActivatedComponent(snapshot.firstChild);
//     }

//     return snapshot.component;
//   }

//   private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
//     let path = '';
//     if (snapshot.routeConfig) {
//       path += snapshot.routeConfig.path;
//     }

//     if (snapshot.firstChild) {
//       return path + this.getRouteTemplate(snapshot.firstChild);
//     }

//     return path;
//   }

// }
