import { diff3Merge } from 'node-diff3';
import { CloudFileProvider, CloudFile } from '../fileproviders';
import TodoFileSerializer from './todo-format';
import { TodoItem } from './model';
import { logger } from 'src/app/logger';

export interface SaveResult {
  todos: TodoItem[];
  cloudFile: CloudFile;
  hadConflict: boolean;
  hadUnresolvableConflict: boolean;
}

export class ConflictResolvingSaver {

  readonly serializer = new TodoFileSerializer();

  constructor(private cloudFileProvider: CloudFileProvider) {
  }

  async saveTodos(path: string, version: string, todos: TodoItem[], originalContent: string): Promise<SaveResult> {
    let shouldContinue = true;
    const originalVersion = version;
    let intermediateResolution: SaveResult;

    while (shouldContinue) {
      shouldContinue = false;

      let content = this.serializer.toFile(todos);
      try {
        const result = await this.cloudFileProvider.putFile(path, { content: content, version: version });

        return {
          hadConflict: false,
          hadUnresolvableConflict: false,
          cloudFile: { content, version: result },
          todos: version === originalVersion
            ? todos // this is really just an optimization to not allocate and to allow object refequals prevention of ui update
            : this.conserveTodosAndIds(todos, this.serializer.fromFile(content))
        };
      } catch (error) {
        if (error.message === 'conflict') {
          logger.info('Got conflict attempting to write file to file provider');
          intermediateResolution = await this.attemptResolve(path, content, originalContent);
          if (!intermediateResolution.hadUnresolvableConflict) {
            logger.info('Server-side changes can be merged with local changes without conflict.');
            ({ content, version } = intermediateResolution.cloudFile);
            shouldContinue = true;
          }
        } else {
          throw (error);
        }
      }
    }
    return intermediateResolution;
  }

  private async attemptResolve(path: string, content: string, originalContent: string): Promise<SaveResult> {
    // get latest from server = B
    // need to get base O - either store it or get it again from the server
    const conflictingFile = await this.cloudFileProvider.getFile(path);

    const result = <any[]>diff3Merge(conflictingFile.content, originalContent, content);
    logger.info(`Finished diff3 on AOB changes. result has length ${result.length}`);

    if (result.length === 1 && result[0].ok) {
      const merged = <string[]>result[0].ok;
      return {
        hadConflict: true,
        hadUnresolvableConflict: false,
        cloudFile: { content: merged.join(''), version: conflictingFile.version },
        todos: null
      };
    }

    return { hadConflict: true, hadUnresolvableConflict: true, cloudFile: conflictingFile, todos: null };
  }

  // Attempt to match up new TodoItems with previous after conflict resolution
  private conserveTodosAndIds(source: TodoItem[], dest: TodoItem[]): TodoItem[] {
    // Build a lookup map using the raw (unparsed line string) of each item
    const rawMap = new Map<string, TodoItem>();
    for (const sourceItem of source) {
      rawMap.set(sourceItem.raw, sourceItem);
    }

    const result = [];
    for (const destItem of dest) {
      const match = rawMap.get(destItem.raw);
      let toAdd = destItem;

      if (match) {

        if (match.lineNumber === destItem.lineNumber) {
          // if line number also matches, just re-use the previous object
          toAdd = match;
        } else {
          // if the todo item was moved to a different line, just re-use the ID
          destItem.id = match.id;
        }

        // Remove this item from consideration for other matches
        rawMap.delete(destItem.raw);
      }

      result.push(toAdd);
    }

    return result;
  }
}
