import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Actions } from '../store';
import { dispatch } from '@angular-redux/store';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage {
  constructor() {
  }

  @dispatch()
  public logout = () => Actions.logout()
}
