import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, RestorePreviousFilterRouterGuard } from './app-routing.canactivate';
import { StoreModule } from './store';

import { LoginPage } from './login/login.page';
import { HomePage } from './home/home.page';
import { AuthorPage } from './author/author.page';
import { SettingsPage } from './settings/settings.page';
import { LogPage } from './log/log.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomePage,
    canActivate: [AuthGuard, RestorePreviousFilterRouterGuard]
  },
  {
    path: 'list',
    component: HomePage,
    canActivate: [AuthGuard]
  },
  {
    path: 'list/tag/:type/:tag',
    component: HomePage,
    canActivate: [AuthGuard]
  },
  {
    path: 'add',
    component: AuthorPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit/:todoId',
    component: AuthorPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'settings',
    component: SettingsPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'log',
    component: LogPage,
    canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), StoreModule],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
