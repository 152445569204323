<ion-content text-center>
  <ion-grid>
    <ion-row justify-content-center align-items-center>
      <ion-col>
        <div [ngClass]="{ loading: loading | async }">
          <div class="loading-content">
            <ion-spinner name="dots" size="large"></ion-spinner>
          </div>
          <div class="login-button-content">
            <ion-button size="large" (click)="onLoginButtonClick()">
              <ion-icon name="logo-dropbox" slot="start"></ion-icon>
              Login to Dropbox
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>