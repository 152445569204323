// import { Buffer } from 'buffer'

import { CloudFile, CloudFileProvider } from './model';

export class DropboxFileProvider implements CloudFileProvider {
  private _accessToken: string;

  constructor(accessToken: string) {
    this._accessToken = accessToken;
  }

  async getFile(path: string): Promise<CloudFile> {
    const args = {
      path: path
    };

    const resp = await fetch(
      'https://content.dropboxapi.com/2/files/download', {
        headers: new Headers({
          'Authorization': 'Bearer ' + this._accessToken,
          'Dropbox-API-Arg': JSON.stringify(args)
        })
      }
    );

    const content = await resp.text();

    if (resp.status === 409 && resp.statusText.startsWith('path/not_found')) {
      throw new Error('notfound');
    }

    if (resp.status !== 200) {
      throw content;
    }

    const apiResult = resp.headers.get('dropbox-api-result');
    if (apiResult === null) {
      throw new Error('No header dropbox-api-result on response');
    }

    const metadata = this.parseDropboxMetadata(apiResult);

    return { content: content, version: metadata.rev };
  }

  async putFile(path: string, file: CloudFile): Promise<string> {
    const mode = file.version ? {
      '.tag': 'update',
      'update': file.version
    } : { '.tag': 'add' };

    const args = {
      path: path,
      mode: mode,
      mute: true,
      autorename: false
    };

    const resp = await fetch(
      'https://content.dropboxapi.com/2/files/upload', {
        method: 'POST',
        body: file.content, // Buffer.from(file.content, 'utf8'),
        headers: new Headers({
          'Authorization': 'Bearer ' + this._accessToken,
          'Dropbox-API-Arg': JSON.stringify(args),
          'Content-Length': file.content.length.toString(),
          'Content-Type': 'application/octet-stream'
        })
      }
    );

    const content = await resp.text();
    console.log(resp.status);
    console.log(content);

    if (resp.status === 409) {
      throw new Error('conflict');
    }

    if (resp.status !== 200) {
      throw content;
    }
    /*
    if(error.stack.includes('[409]')){
        throw new Meteor.Error(
        'conflict',
        'File was edited elsewhere. Your change has been lost, you will have to do it again.');
    }
    else if (error.stack.includes('[507]')){
        throw new Meteor.Error(
        'bad-request',
        'Dropbox was unable to complete this action because of a storage quota limit.');
    }
    */

    const metadata = this.parseDropboxMetadata(content);

    return metadata.rev;
  }

  private parseDropboxMetadata(json: string): DropboxFileMetadata {
    return <DropboxFileMetadata>JSON.parse(json);
  }
}

interface DropboxFileMetadata {
  rev: string;
}
