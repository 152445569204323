import uuidv4 from 'uuid/v4';

export interface TodoItem {
  id: string;

  lineNumber: number;
  raw: string;

  isCompleted: boolean;
  priority: string | null;

  body: string;

  projects: string[];
  contexts: string[];

  dueDate: Date | null;
  completedDate: Date | null;
}

export const TodoItem = {
  build: (item?: Partial<TodoItem>): TodoItem => {
    item = item || {};
    return {
      id: item.id || uuidv4(),
      lineNumber: item.lineNumber || -1,
      raw: item.raw || '',
      isCompleted: item.isCompleted || false,
      priority: item.priority || null,
      body: item.body || '',
      projects: (item.projects || []).slice(),
      contexts: (item.contexts || []).slice(),
      dueDate: item.dueDate || null,
      completedDate: item.completedDate || null
    };
  }
};

export enum TodoOperationType {
  SET_IS_COMPLETED = 'SET_IS_COMPLETED',

  UPSERT_TODO = 'UPSERT_TODO'
}

export interface BaseTodoOperation {
  type: string;
}

export interface TodoOperationOnExisting extends BaseTodoOperation {
  todoId: string;
}

export interface SetIsCompletedTodoOperation extends TodoOperationOnExisting {
  type: TodoOperationType.SET_IS_COMPLETED;

  isCompleted: boolean;
}

export interface UpsertTodoOperation extends BaseTodoOperation {
  type: TodoOperationType.UPSERT_TODO;

  todo: TodoItem;
}

export type TodoOperation = SetIsCompletedTodoOperation | UpsertTodoOperation;

export interface TodoOperationApplication {
  operation: TodoOperation;
}
