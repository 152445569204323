import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { ListPageModule } from '../list/list.module';

import { HomePage } from './home.page';
import { SideMenuComponent } from './side-menu/side-menu.component';
// import { StoreModule } from '../store';

@NgModule({
  imports: [
    // StoreModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ListPageModule
  ],
  declarations: [HomePage, SideMenuComponent]
})
export class HomePageModule { }
