import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LoginPageModule } from './login/login.module';
import { HomePageModule } from './home/home.module';
import { AuthorPageModule } from './author/author.module';

import { StoreModule } from './store';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MonitoringErrorHandler } from './monitoring';
import { SettingsPageModule } from './settings/settings.module';
import { LogPageModule } from './log/log.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    StoreModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgReduxModule,
    LoginPageModule,
    HomePageModule,
    AuthorPageModule,
    SettingsPageModule,
    LogPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.useServiceWorker })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: MonitoringErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
