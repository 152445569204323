<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Log</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding>

  <ng-template [ngIf]="synchronizationInProgress$ | async" [ngIfElse]="syncNotInProgress">
    <ion-card>
      <ion-card-header>
        <ion-card-title>Synchronization in Progress</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <app-sync-status></app-sync-status>
      </ion-card-content>
    </ion-card>
  </ng-template>

  <ng-template #syncNotInProgress>
  </ng-template>

  <ion-virtual-scroll [items]="history$ | async" [trackBy]="getLocalRevisionId" approxItemHeight="67px">
    <ion-card *virtualItem="let item">
      <ion-card-content>
        localRevisionId: {{item.localRevisionId}} <br />
        remoteRevisionId: {{item.remoteRevisionId}} <br />
        localTime: {{item.localTime}} <br />
      </ion-card-content>
    </ion-card>

  </ion-virtual-scroll>
</ion-content>