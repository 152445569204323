<ion-item-group>
  <ion-item-divider>
    <ion-label>Contexts</ion-label>
  </ion-item-divider>

  <ion-item *ngFor="let tag of contexts$ | async; trackBy:getTagId" lines="none" detail="false" button="true"
    [routerLink]="['/list/tag/context', tag.name]" routerDirection="forward">
    <ion-icon name="at" slot="start"></ion-icon>
    <ion-label>{{tag.name}}</ion-label>
    <ion-badge color="light">{{tag.occurrences}}</ion-badge>
  </ion-item>
</ion-item-group>

<ion-item-group>
  <ion-item-divider>
    <ion-label>Projects</ion-label>
  </ion-item-divider>

  <ion-item *ngFor="let tag of projects$ | async; trackBy:getTagId" lines="none" detail="false" button="true"
    [routerLink]="['/list/tag/project', tag.name]" routerDirection="forward">
    <ion-icon name="add-circle" slot="start"></ion-icon>
    <ion-label>{{tag.name}}</ion-label>
    <ion-badge color="light" slot="end">{{tag.occurrences}}</ion-badge>
  </ion-item>
</ion-item-group>