import { OperatorFunction, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject { [actionCreator: string]: FunctionType; }

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export type Action<T extends string = string, P = void> = P extends void
  ? Readonly<{ type: T }>
  : Readonly<{ type: T; payload: P }>;

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P
): Action<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  const action = payload === undefined ? { type } : { type, payload };

  return action;
}

export type ActionsOfType<
  ActionUnion,
  ActionType extends string
  > = ActionUnion extends Action<ActionType> ? ActionUnion : never;

export function ofActionsUnionType<V, T1 extends string>(
  t1: T1
): OperatorFunction<V, ActionsOfType<V, T1>>;
export function ofActionsUnionType<V, T1 extends string, T2 extends string>(
  t1: T1,
  t2: T2
): OperatorFunction<V, ActionsOfType<V, T1 | T2>>;
export function ofActionsUnionType<
  V,
  T1 extends string,
  T2 extends string,
  T3 extends string
>(t1: T1, t2: T2, t3: T3): OperatorFunction<V, ActionsOfType<V, T1 | T2 | T3>>;
export function ofActionsUnionType<
  V,
  T1 extends string,
  T2 extends string,
  T3 extends string,
  T4 extends string
>(
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4
): OperatorFunction<V, ActionsOfType<V, T1 | T2 | T3 | T4>>;
export function ofActionsUnionType<
  V,
  T1 extends string,
  T2 extends string,
  T3 extends string,
  T4 extends string,
  T5 extends string
>(
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4,
  t5: T5
): OperatorFunction<V, ActionsOfType<V, T1 | T2 | T3 | T4 | T5>>;

export function ofActionsUnionType(...keys: string[]) {
  return (source: Observable<Action>) =>
    source.pipe(filter((action) => keys.indexOf(action.type) !== -1));
}
