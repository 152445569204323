import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dispatch } from '@angular-redux/store';
import uuidv4 from 'uuid/v4';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { configuration } from '../../environments/configuration';
import { Actions } from '../store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPage implements OnInit, OnDestroy {

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  browserLoadStartSubscription: Subscription = null;

  constructor(
    private route: ActivatedRoute,
    private platform: Platform,
    private iab: InAppBrowser) { }

  ngOnInit(): void {
    const token: string = this.route.snapshot.queryParamMap.get('token');

    if (token) {
      this.dispatchSetUser(token);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeBrowserLoadStartSubscription();
  }

  public onLoginButtonClick = () => {
    const state = configuration.ouathStatePrefix + '_' + uuidv4() + '-' + uuidv4();
    this.loading.next(true);
    fetch(configuration.apiUrl + '/api/oauth/warm');

    const redirectUri = configuration.apiUrl + '/api/oauth/receive';
    const oauthUri = 'https://www.dropbox.com/oauth2/authorize?response_type=code&client_id=' +
      configuration.dropbox.clientId +
      '&redirect_uri=' +
      encodeURIComponent(redirectUri) +
      '&state=' + state;
    // const oauthUri = 'https://txtodo-win-eastus.azurewebsites.net/api/oauth/forward?state=' + state;

    let browserTarget = '_self';
    if (this.platform.is('cordova')) {
      browserTarget = '_blank';
    }
    const browser = this.iab.create(oauthUri, browserTarget, 'beforeload=yes,location=yes');

    this.browserLoadStartSubscription = browser.on('loadstart').subscribe(event => {
      console.log(event);
      if (event.url.includes('?token=')) {
        const token = event.url.substring(event.url.indexOf('?token=') + '?token='.length);

        browser.close();
        this.unsubscribeBrowserLoadStartSubscription();

        this.dispatchSetUser(token);
      }
    });
  }

  unsubscribeBrowserLoadStartSubscription() {
    if (this.browserLoadStartSubscription) {
      this.browserLoadStartSubscription.unsubscribe();
      this.browserLoadStartSubscription = null;
    }
  }

  @dispatch()
  dispatchSetUser = (token: string) => Actions.setUser({ token })
}
