import { Component, OnInit, HostListener, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { select, dispatch } from '@angular-redux/store';
import { Observable, combineLatest, Subscription } from 'rxjs';

import { TodoItem, sortTodos } from '../lib/todotxtformat';
import { map } from 'rxjs/operators';

import { Actions } from '../store';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PopoverController, MenuController } from '@ionic/angular';
import { ListMorePopoverComponent } from './list-more-popover.component';
import { ListSyncPopoverComponent } from './list-sync-popover.component';
import { HomePage } from '../home/home.page';

interface ListGroup<TItem> {
  items: TItem[];
  title: string;
}

@Component({
  selector: 'app-list',
  templateUrl: 'list.page.html',
  styleUrls: ['list.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPage implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private popoverController: PopoverController,
    private menu: MenuController) {
  }

  @select() list$: Observable<TodoItem[]>;
  @select(['synchronization', 'inProgress']) syncing$: Observable<boolean>;
  @select(['user', 'settings', 'useVirtualScroll']) useVirtualScroll$: Observable<boolean>;

  syncingSubscription: Subscription;
  manualSyncEvents: any[] = [];

  displayList$: Observable<TodoItem[]>;
  displayListGrouped$: Observable<ListGroup<TodoItem>[]>;

  title$: Observable<string>;

  ngOnInit(): void {
    const route$: Observable<{ type: string, tag: string } | null> = this.route.paramMap.pipe(
      map(paramMap => this._getRouteInfo(paramMap))
    );
    const filter$: Observable<(todo: TodoItem) => Boolean> = route$.pipe(
      map(route => {
        if (!route) { return (todo: TodoItem) => true; }
        if (route.type === '+') {
          return (todo: TodoItem) =>
            todo.projects.some(t => t.toLowerCase() === route.tag.toLowerCase());
        }
        if (route.type === '@') {
          return (todo: TodoItem) =>
            todo.contexts.some(t => t.toLowerCase() === route.tag.toLowerCase());
        }
        throw new Error('nope');
      })
    );

    this.displayList$ = combineLatest(this.list$, filter$).pipe(
      map(([todos, filter]) => todos.filter(filter).sort(sortTodos)),
      // map(todos => this._groupByPri(todos))
    );
    this.displayListGrouped$ = this.displayList$.pipe(
      map(todos => this._groupByPri(todos))
    );
    this.title$ = route$.pipe(map(route => route ? route.type + route.tag : 'All Todos'));

    this.syncingSubscription = this.syncing$.subscribe(inProgress => {
      if (!inProgress) {
        this.manualSyncEvents.forEach(e => e.target.complete());
        this.manualSyncEvents = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.syncingSubscription.unsubscribe();
  }

  getGroupTitle(_, group: ListGroup<TodoItem>) {
    return group.title;
  }
  getTodoId(_, todo: TodoItem) {
    return todo.id;
  }

  openAddPage() {
    this.router.navigate(['/add']);
  }

  getSectionDivider(record: TodoItem, recordIndex: number, records: TodoItem[]) {
    if (recordIndex === 0 || records[recordIndex - 1].priority !== record.priority) {
      return record.priority;
    }
    return null;
  }

  async presentMorePopover(ev: any) {
    const popover = await this.popoverController.create({
      component: ListMorePopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  async presentSyncPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: ListSyncPopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  @dispatch() dispatchSyncBegin = () => Actions.syncBegin();

  public doRefresh(event) {
    this.dispatchSyncBegin();

    this.manualSyncEvents.push(event);
  }

  private _getRouteInfo(paramMap: ParamMap) {
    {
      if (paramMap.has('type') && paramMap.has('tag')) {
        const type = paramMap.get('type');
        const tag = paramMap.get('tag');
        if (type && tag) {
          if (type === 'project') {
            return { type: '+', tag };
          }
          if (type === 'context') {
            return { type: '@', tag };
          }
        }
      }
      return null;
    }
  }

  private _groupByPri(todos: TodoItem[]): ListGroup<TodoItem>[] {
    let group: ListGroup<TodoItem> = {
      title: 'NOPE',
      items: []
    };
    const result = [];
    for (const todo of todos) {
      const todoGroup = todo.isCompleted ? 'Completed' : todo.priority;
      if (todoGroup !== group.title) {
        group = {
          items: [todo],
          title: todoGroup
        };
        result.push(group);
      } else {
        group.items.push(todo);
      }
    }
    return result;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);
    const component = this.route.snapshot.component;

    if (component === HomePage) {
      if (event.keyCode === 65 /* A */) {
        // console.log('Opening add page because A was pressed');
        this.openAddPage();
      }
      if (event.code === 'KeyM' /* M */) {
        this.menu.toggle('main');
      }
      if (event.code === 'Escape') {
        this.menu.isOpen('main').then(isOpen => {
          if (isOpen) {
            // console.log('Should close menu because Escape was pressed');
            this.menu.close('main');
          }
        });
      }
    }
  }
}
