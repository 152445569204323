import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { Epic } from 'redux-observable';
import { TodoFileSerializer, ConflictResolvingSaver } from '../lib/todotxtformat';
import { DropboxFileProvider } from '../lib/fileproviders';

import { of, from, EMPTY } from 'rxjs';
import { mergeMap, withLatestFrom, map, catchError, concatMap, filter } from 'rxjs/operators';

import { AppState } from './model';

import { ofActionsUnionType } from './action-util';
import { ActionTypes, Actions, ActionOfType } from './actions';

import { logger } from '../logger';

@Injectable()
export class RootEpics {
  constructor(/*private http: Http*/) { }

  getAll() {
    return [
      this.onNavigate,
      this.triggerBeginSync,
      this.beginSync,
      this.syncWork,
      this.completeSync,
    ];
  }

  onNavigate: Epic<Actions, ActionOfType<typeof ActionTypes.SET_LAST_USED_FILTER>, AppState>
    = (action$, state$) => {
      return action$.pipe(
        ofActionsUnionType(ActionTypes.NAVIGATE),
        withLatestFrom(state$),
        filter(([action, state]) => action.payload.startsWith('/list')),
        map(([action, state]) => Actions.setLastUsedFilter({ route: action.payload }))
      );
    }

  triggerBeginSync: Epic<Actions, ActionOfType<typeof ActionTypes.SYNC_BEGIN>, AppState>
    = (action$, state$) => {
      return action$.pipe(
        ofActionsUnionType(ActionTypes.STARTUP, ActionTypes.SET_USER, ActionTypes.MUTATE),
        withLatestFrom(state$),
        filter(([action, state]) => !!state.user && !!state.user.token),
        map(([action, state]) => Actions.syncBegin({ syncId: action.type === ActionTypes.STARTUP ? state.synchronization.syncId : null }))
      );
    }

  beginSync: Epic<Actions, ActionOfType<typeof ActionTypes.SYNC_WORK>, AppState>
    = (action$, state$) => {
      return action$.pipe(
        ofActionsUnionType(ActionTypes.SYNC_BEGIN),
        withLatestFrom(state$),
        filter(([action, state]) => {
          if (!state.synchronization.inProgress || action.payload.syncId !== state.synchronization.syncId) {
            logger.verbose(`Not starting beginSync epic because action syncId ${action.payload.syncId} != ${state.synchronization.syncId}`);
            return false;
          }
          return true;
        }),
        map(([action, state]) => Actions.syncWork({ syncId: action.payload.syncId }))
      );
    }

  syncWork: Epic<Actions, ActionOfType<typeof ActionTypes.SYNC_COMPLETE> | ActionOfType<typeof ActionTypes.SYNC_FAIL>, AppState>
    = (action$, state$) => {
      return action$.pipe(
        ofActionsUnionType(ActionTypes.SYNC_WORK),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {

          const shouldWrite = !!state.synchronization.remoteRevisionId;

          const fileProvider = new DropboxFileProvider(state.user.token);

          if (shouldWrite) {
            const conflictResolver = new ConflictResolvingSaver(fileProvider);

            return from(
              conflictResolver.saveTodos(state.user.settings.todoFilePath,
                state.synchronization.remoteRevisionId,
                state.synchronization.todos,
                state.synchronization.lastSyncedTodoFileContent))
              .pipe(
                map(saveResult => Actions.syncComplete({
                  syncId: action.payload.syncId,
                  localRevisionId: state.synchronization.localRevisionId,
                  remoteRevisionId: saveResult.cloudFile.version,
                  todos: saveResult.todos,
                  todoFileContent: saveResult.cloudFile.content,
                  hadConflict: saveResult.hadConflict,
                  hadUnresolvableConflict: saveResult.hadUnresolvableConflict
                })),
                catchError(error => of(Actions.syncFail({ syncId: action.payload.syncId })))
              );
          } else {
            const serializer = new TodoFileSerializer();

            return from(fileProvider.getFile(state.user.settings.todoFilePath)).pipe(
              map(result => Actions.syncComplete({
                syncId: action.payload.syncId,
                localRevisionId: state.synchronization.localRevisionId,
                todos: serializer.fromFile(result.content),
                remoteRevisionId: result.version,
                hadConflict: false,
                hadUnresolvableConflict: false,
                todoFileContent: result.content
              })
              ),
              catchError(error => {
                if (error.message === 'notfound') {
                  logger.info('Got a notfound response while retrieving todo file, will create an initial file');

                  const content = '(A) This is your first example todo item';
                  return from(fileProvider.putFile(state.user.settings.todoFilePath, { content: content, version: null })).pipe(
                    map(result => Actions.syncComplete({
                      syncId: action.payload.syncId,
                      localRevisionId: state.synchronization.localRevisionId,
                      todos: serializer.fromFile(content),
                      remoteRevisionId: result,
                      hadConflict: false,
                      hadUnresolvableConflict: false,
                      todoFileContent: content
                    })),
                    catchError(e => of(Actions.syncFail({ syncId: action.payload.syncId })))
                  );
                } else {
                  return of(Actions.syncFail({ syncId: action.payload.syncId }));
                }
              })
            );
          }
        })
      );
    }

  completeSync: Epic<Actions, ActionOfType<typeof ActionTypes.SYNC_WORK>, AppState>
    = (action$, state$) => {
      return action$.pipe(
        ofActionsUnionType(ActionTypes.SYNC_COMPLETE),
        withLatestFrom(state$),
        filter(([action, state]) => {
          const payload = action.payload;
          if (!state.synchronization.inProgress || payload.syncId !== state.synchronization.syncId) {
            logger.verbose(`completeSync epic not starting a new sync because sync inProgress==${state.synchronization.inProgress} or ` +
              `action syncId "${payload.syncId}" != "${state.synchronization.syncId}"`);
            return false;
          }
          return true;
        }),
        /*filter(([action, state]) => {
          const lastHistoryEntry = state.history.slice(-1)[0];

          if (!lastHistoryEntry.remoteRevisionId) {
            logger.verbose(`completeSync epic starting a new sync because newest localRevision ` +
              `${lastHistoryEntry.localRevisionId} has no remoteRevisionId `);
            return true;
          }
          return false;
        }),*/
        map(([action, state]) => Actions.syncWork({ syncId: action.payload.syncId })
        )
      );
    }
}
