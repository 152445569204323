import {
  Component, OnInit, Input,
  ChangeDetectionStrategy, ViewChild, AfterContentChecked
} from '@angular/core';
import { TodoItem, TodoOperationType } from 'src/app/lib/todotxtformat';
import { Actions } from 'src/app/store';
import { dispatch } from '@angular-redux/store';
import { IonCheckbox } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'todo-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent implements OnInit, AfterContentChecked {

  private didKillInputCover = false;

  @Input()
  item: TodoItem;

  @ViewChild('checkbox') checkbox: IonCheckbox;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onItemClick(event) {
    this.router.navigate(['/edit', this.item.id]);
  }

  ngAfterContentChecked(): void {
    if (!this.didKillInputCover) {
      const el = (this.checkbox as any).el;
      if (el && el.shadowRoot) {
        const button = el.shadowRoot.querySelector('button');
        if (button) {
          button.remove();
          this.didKillInputCover = true;
        }
      }
    }
  }

  @dispatch()
  onCheckboxClick(event) {
    event.stopPropagation();
    return Actions.mutateTodos({
      ops: [
        {
          type: TodoOperationType.SET_IS_COMPLETED,
          todoId: this.item.id,
          isCompleted: !this.item.isCompleted
        }]
    });
  }
}
