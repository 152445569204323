import { Actions } from './actions';
import { Store, AnyAction } from 'redux';
import { AppState } from './model';

import { logger } from '../logger';
// import { AppInsights } from '../app-insights';

// Want to remove any 'user-information' and instead provide summaries, statistics, and generalized application state
function computeLoggableState(state: AppState) {
  return {
    // router: state.router,
    user: {
      hasUser: !!state.user,
      hasToken: !!(state.user || { token: null }).token
    },
    history: {
      length: state.history.length,
      unsynced: Math.max(state.history.slice().reverse().findIndex(h => !!h.remoteRevisionId), 0)
    },
    list: {
      length: state.list.length,
      completed: state.list.filter(t => t.isCompleted).length
    }
  };
}

export const monitoringMiddleware = (store: Store<AppState, AnyAction>) => (next: (action: Actions) => void) => (action: Actions) => {
  const lastState = store.getState();
  next(action);
  const newState = store.getState();
  const loggableState = JSON.stringify(computeLoggableState(newState));
  logger.verbose(`Completed dispatch of action with type='${action.type}'. State=${loggableState}`);
};
