import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { NgRedux } from '@angular-redux/store';
import { AppState } from './store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private ngRedux: NgRedux<AppState>
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    const lastState = this.ngRedux.getState();

    if (!!lastState.user) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RestorePreviousFilterRouterGuard implements CanActivate {
  constructor(
    private router: Router,
    private ngRedux: NgRedux<AppState>
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    const lastState = this.ngRedux.getState();

    this.router.navigate([lastState.view.lastUsedFilterRoute]);
    return false;
  }
}
