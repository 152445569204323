import { Reducer, AnyAction, Action } from 'redux';
import { routerReducer } from '@angular-redux/router';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';
import uuidv4 from 'uuid/v4';

import { AppState, AppliedTodoOperationSet, INITIAL_STATE, INITIAL_USER_SETTINGS } from './model';
import { Actions, ActionTypes, ActionOfType } from './actions';

import { TodoItem, TodoFileSerializer, TodoOperationApplication } from '../lib/todotxtformat';

import { logger } from '../logger';

// function createReducer<T extends ActionTypes>(type: T, redFunc: (lastState: AppState, action: ActionOfType<T>) => AppState)
//   : Reducer<AppState> {
//   return (lastState: AppState, action: Actions) => {
//     if (!action && action.type !== type) { return lastState; }
//     return redFunc(lastState, action);
//   };
// }

export const setUserReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (action && action.type === ActionTypes.SET_USER) {
    logger.info('Setting new user token.');
    return {
      ...lastState,
      user: { name: 'bob', token: action.payload.token, settings: INITIAL_USER_SETTINGS },
      router: '/home'
    };
  }
  return lastState;
};

export const logoutReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.LOGOUT) { return lastState; }

  return { ...INITIAL_STATE, router: '/login' };
};

export const syncBeginReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.SYNC_BEGIN) { return lastState; }

  if (lastState.synchronization.inProgress && lastState.synchronization.syncId !== action.payload.syncId) {
    logger.verbose(`Not starting sync because another sync ${action.payload.syncId} is already in progress.`);
    return lastState;
  }

  if (action.payload.skipIfSyncedSinceMs &&
    new Date().getTime() - lastState.synchronization.lastCompleteTime < action.payload.skipIfSyncedSinceMs &&
    lastState.synchronization.syncId !== action.payload.syncId) {
    logger.verbose(`Not starting sync because last synchonization was at ${lastState.synchronization.lastCompleteTime}.`);
    return lastState;
  }

  logger.info(`Setting state.synchronization: { inProgress: true, syncId: ${action.payload.syncId} }`);

  return {
    ...lastState,
    synchronization: {
      ...lastState.synchronization,
      syncId: action.payload.syncId,
      inProgress: true
    }
  };
};

export const syncWorkReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.SYNC_WORK) { return lastState; }

  let localRevisionId = lastState.synchronization.localRevisionId;
  let lastHistoryEntryHasRemoteRevisionId = false;
  if (localRevisionId) {
    logger.verbose(`Sync will use existing intermediate localRevisionId from previous sync attempt.`);
  } else {
    if (lastState.history.length > 0) {
      const lastHistoryEntry = lastState.history.slice(-1)[0];
      localRevisionId = lastHistoryEntry.localRevisionId;
      lastHistoryEntryHasRemoteRevisionId = !!lastHistoryEntry.remoteRevisionId;
      logger.verbose(`Sync will use most recent localRevisionId from history.`);
    } else {
      logger.verbose(`Sync will use empty localRevisionId.`);
    }
  }

  let remoteRevisionId = lastState.synchronization.remoteRevisionId;
  if (remoteRevisionId) {
    logger.verbose(`Sync will use existing intermediate remoteRevisionId from previous sync attempt.`);
  } else {
    if (lastState.history.length > 0 && !lastHistoryEntryHasRemoteRevisionId) {
      const historyEntryWithRemoteRevision = lastState.history.slice().reverse().find(aos => !!aos.remoteRevisionId);
      if (historyEntryWithRemoteRevision) {
        remoteRevisionId = historyEntryWithRemoteRevision.remoteRevisionId;
        logger.verbose(`Sync will use most recent remoteRevisionId from history.`);
      }
    } else {
      logger.verbose(`Sync will not write because history is empty or last history entry was written.`);
    }
  }

  let todos = lastState.synchronization.todos;
  if (todos) {
    logger.verbose(`Sync will use existing intermediate todos from previous sync attempt.`);
  } else {
    todos = lastState.list;
    logger.verbose(`Sync will use todos from active list.`);
  }

  logger.info(`Setting sync inProgress to state with syncId=${action.payload.syncId},`
    + ` localRevisionId=${localRevisionId}, hasRemoteRevisionId=${!!remoteRevisionId}`);

  return {
    ...lastState,
    synchronization: {
      ...lastState.synchronization,
      localRevisionId,
      remoteRevisionId,
      todos
    }
  };
};

export const syncCompleteReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.SYNC_COMPLETE) { return lastState; }

  const payload = action.payload;

  if (lastState.history.some(h => h.remoteRevisionId === action.payload.remoteRevisionId)) {
    logger.verbose(`Not changing list or history on sync complete because remoteRevisionId already in history`);
    return {
      ...lastState,
      synchronization: {
        ...INITIAL_STATE.synchronization,
        lastCompleteTime: new Date().getTime(),
        lastSyncedTodoFileContent: payload.todoFileContent
      }
    };
  }

  let lastStateLatestLocalRevision: string = null;
  if (lastState.history.length > 0) {
    lastStateLatestLocalRevision = lastState.history.slice(-1)[0].localRevisionId;
  }

  if (lastStateLatestLocalRevision !== payload.localRevisionId) {
    logger.verbose(`Will trigger another round of sync because ` +
      `new localRevisionId ${lastStateLatestLocalRevision} has supplanted ${payload.localRevisionId}`);

    return {
      ...lastState,
      synchronization: {
        ...lastState.synchronization,
        localRevisionId: lastStateLatestLocalRevision,
        remoteRevisionId: uuidv4(), // Being lazy here and forcing a conflict //payload.remoteRevisionId,
        todos: lastState.list,
        lastSyncedTodoFileContent: payload.todoFileContent
      }
    };
  }

  let history: AppliedTodoOperationSet[];
  if (lastState.history.length > 0) {
    const targetIndex = lastState.history.findIndex(t => t.localRevisionId === payload.localRevisionId);
    if (targetIndex === -1) {
      throw new Error(`Could not find matching localRevisionId ${payload.localRevisionId} in history`);
    }
    const historyEntryToUpdate = lastState.history[targetIndex];

    logger.verbose(`Adding new remoteRevisionId to history entry at `
      + `index ${targetIndex} with localRevisionId ${historyEntryToUpdate.localRevisionId}`);

    history = lastState.history.slice(0, targetIndex)
      .concat([{ ...historyEntryToUpdate, remoteRevisionId: payload.remoteRevisionId }])
      .concat(lastState.history.slice(targetIndex + 1, lastState.history.length));
  } else {
    logger.verbose(`History is empty at sync complete so adding new entry.`);
    history = [{
      remoteRevisionId: payload.remoteRevisionId,
      localRevisionId: uuidv4(),
      operationApplications: [],
      localTime: new Date().getTime()
    }];
  }

  return {
    ...lastState,
    history: history,
    list: payload.todos,
    synchronization: {
      ...INITIAL_STATE.synchronization,
      lastCompleteTime: new Date().getTime(),
      lastSyncedTodoFileContent: payload.todoFileContent
    }
  };
};

export const syncFailReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.SYNC_FAIL) { return lastState; }

  return {
    ...lastState,
    synchronization: {
      ...INITIAL_STATE.synchronization,
      lastCompleteTime: lastState.synchronization.lastCompleteTime,
      lastSyncedTodoFileContent: lastState.synchronization.lastSyncedTodoFileContent
    }
  };
};


export const mutateTodosReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.MUTATE) { return lastState; }

  let todos: TodoItem[] = lastState.list;
  let operationApplication: TodoOperationApplication;
  const operationApplications: TodoOperationApplication[] = [];

  const todoFormat = new TodoFileSerializer();
  for (const op of action.payload.ops) {
    ({ todos, operationApplication } = todoFormat.applyOperation(op, todos));
    operationApplications.push(operationApplication);
  }

  const appliedOperationSet: AppliedTodoOperationSet = {
    localRevisionId: uuidv4(),
    operationApplications: operationApplications,
    localTime: new Date().getTime()
  };

  let history: AppliedTodoOperationSet[];
  const indexOfLastRemoteRevision = lastState.history
    .slice().reverse().findIndex(aos => !!aos.remoteRevisionId);
  if (indexOfLastRemoteRevision === -1) {
    throw new Error(`Could not find any remoteRevisionId when saving.`);
  } else {
    history = lastState.history.slice(
      Math.max(0, indexOfLastRemoteRevision - 99),
      lastState.history.length).concat(appliedOperationSet);
  }

  logger.verbose(`Applied todolist mutation with ${appliedOperationSet.operationApplications.length} ops ` +
    ` creating new localRevisionId ${appliedOperationSet.localRevisionId}`);

  return {
    ...lastState,
    list: todos,
    history: history
  };
};

export const setLastUsedFilterReducer: Reducer<AppState> = (lastState: AppState, action: Actions): AppState => {
  if (!action || action.type !== ActionTypes.SET_LAST_USED_FILTER) { return lastState; }

  return { ...lastState, view: { ...lastState.view, lastUsedFilterRoute: action.payload.route } };
};

export const combinableRouterReducer: Reducer<AppState> = (lastState: AppState, action: AnyAction): AppState =>
  ({ ...lastState, router: routerReducer(lastState.router, action) });

export const rootReducer: Reducer<AppState> = composeReducers(
  defaultFormReducer(),
  (lastState: AppState, action: AnyAction): AppState => {
    const reducers = [
      setUserReducer, logoutReducer,
      mutateTodosReducer,
      syncBeginReducer, syncWorkReducer, syncCompleteReducer, syncFailReducer,
      combinableRouterReducer, setLastUsedFilterReducer
    ];
    let state = lastState;
    for (const reducer of reducers) {
      state = reducer(state, action);
    }
    return state;
  });
