import { Component, ChangeDetectionStrategy, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { dispatch } from '@angular-redux/store';
import { Actions } from './store';
import { Title } from '@angular/platform-browser';

import { configuration } from 'src/environments/configuration';
import { logger } from './logger';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly periodicSyncFrequencyMs = 10 * 60 * 1000;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private titleService: Title,
    private ngZone: NgZone
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.dispatchStartup();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.titleService.setTitle(configuration.appTitle);

      window.addEventListener('focus', evnt => {
        logger.verbose(`document.focus: hidden=${document.hidden}, visibilityState=${document.visibilityState}`
          + ` hasFocus=${document.hasFocus()}`);
      });
      window.addEventListener('blur', evnt => {
        logger.verbose(`document.blur: hidden=${document.hidden}, visibilityState=${document.visibilityState}`
          + ` hasFocus=${document.hasFocus()}`);
      });

      window.addEventListener('visibilitychange', evnt => {
        logger.verbose(`document.visibilitychange: hidden=${document.hidden}, visibilityState=${document.visibilityState}`
          + ` hasFocus=${document.hasFocus()}`);
        if (!document.hidden) {
          this.dispatchSyncBegin();
        }
      });
      this.ngZone.runOutsideAngular(() => {
        setInterval(() => {
          logger.verbose(`periodicSync.setInterval: hidden=${document.hidden}, visibilityState=${document.visibilityState}`
            + ` hasFocus=${document.hasFocus()}`);
          if (!document.hidden) {
            this.ngZone.run(() => this.dispatchSyncBegin());
          }
        }, this.periodicSyncFrequencyMs);
      });
    });
  }

  @dispatch()
  dispatchStartup = () => Actions.startup()

  @dispatch()
  dispatchSyncBegin = () => Actions.syncBegin({ skipIfSyncedSinceMs: this.periodicSyncFrequencyMs })
}
