import { NgModule } from '@angular/core';

// import { provideReduxForms } from '@angular-redux/form';
import { NgReduxRouter, NgReduxRouterModule } from '@angular-redux/router';
import {
  DevToolsExtension,
  NgRedux,
  NgReduxModule,
  enableFractalReducers
} from '@angular-redux/store';

import { createEpicMiddleware } from 'redux-observable';

import { createStore, compose, applyMiddleware, AnyAction, Reducer, Middleware, StoreEnhancer } from 'redux';

import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

// Redux ecosystem stuff.
import { createLogger } from 'redux-logger';

// The top-level reducers and epics that make up our app's logic.
// import { RootEpics } from './epics';
import { AppState, INITIAL_STATE, INITIAL_USER_SETTINGS } from './model';
import { rootReducer } from './reducers';
import { RootEpics } from './epics';
import { monitoringMiddleware } from './monitoring';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'list', 'history', 'synchronization', 'schemaVersion', 'view'],
  migrate: (state: AppState) => {
    if (!state) {
      return Promise.resolve(state);
    }
    if (!state.schemaVersion || state.schemaVersion < INITIAL_STATE.schemaVersion) {
      return Promise.resolve(INITIAL_STATE);
    }

    let user = state.user;
    if (user) {
      const userSettings = user.settings || {};
      user = { ...user, settings: { ...INITIAL_USER_SETTINGS, ...userSettings } };
    }
    return Promise.resolve({ ...state, user });
  }
};

const logger = createLogger({
  // ...options
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

function createAppStore(
  reducer: Reducer<AppState, AnyAction>,
  initState: AppState,
  middleware: Middleware[] = [],
  enhancers: StoreEnhancer<AppState>[] = []) {
  return compose.apply(null, [applyMiddleware(...middleware), ...enhancers])(
    createStore
  )(enableFractalReducers(reducer), initState);
}

@NgModule({
  imports: [NgReduxModule, NgReduxRouterModule.forRoot()],
  providers: [RootEpics],
})
export class StoreModule {
  constructor(
    public ngRedux: NgRedux<AppState>,
    devTools: DevToolsExtension,
    ngReduxRouter: NgReduxRouter,
    rootEpics: RootEpics,
  ) {
    const epicMiddleware = createEpicMiddleware<any, any, AppState, any>();

    const enhancers = devTools.isEnabled() ?
      [devTools.enhancer()] :
      [];

    const middleware = [
      logger,
      epicMiddleware,
      monitoringMiddleware
    ];

    const store = createAppStore(persistedReducer, INITIAL_STATE, middleware, enhancers);

    const persistor = persistStore(store);

    for (const epic of rootEpics.getAll()) {
      epicMiddleware.run(epic);
    }

    ngRedux.provideStore(store);

    // Enable syncing of Angular router state with our Redux store.
    if (ngReduxRouter) {
      ngReduxRouter.initialize();
    }

    // Enable syncing of Angular form state with our Redux store.
    // provideReduxForms(store);
  }
}
