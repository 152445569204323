import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

import { TodoItem, TagUtil, TodoTag } from '../../lib/todotxtformat';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnInit {

  constructor() { }

  @select() list$: Observable<TodoItem[]>;

  contexts$: Observable<TodoTag[]>;

  projects$: Observable<TodoTag[]>;

  ngOnInit() {
    this.contexts$ = this.list$.pipe(
      map(items => TagUtil.aggregateContexts(items, false)),
      distinctUntilChanged(this.jsonEquals)
    );
    this.projects$ = this.list$.pipe(
      map(items => TagUtil.aggregateProjects(items, false)),
      distinctUntilChanged(this.jsonEquals),
    );
  }

  getTagId(_, tag: TodoTag) {
    return tag.id;
  }

  private jsonEquals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
}
