import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
// import { RouterModule } from '@angular/router';
import { StoreModule } from '../store';

import { ListPage } from './list.page';
import { ListItemComponent } from './list-item/list-item.component';
import { RouterModule } from '@angular/router';
import { ListMorePopoverComponent } from './list-more-popover.component';
import { LogPageModule } from '../log/log.module';
import { ListSyncPopoverComponent } from './list-sync-popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StoreModule,
    RouterModule,
    LogPageModule
  ],
  declarations: [
    ListPage,
    ListItemComponent,
    ListMorePopoverComponent,
    ListSyncPopoverComponent
  ],
  entryComponents: [
    ListMorePopoverComponent,
    ListSyncPopoverComponent
  ],
  exports: [ListPage]
})
export class ListPageModule { }
