import { Component, OnInit, ViewChild, AfterContentInit, OnDestroy, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { TodoItem, TagUtil, TodoOperationType } from '../lib/todotxtformat';
import { map } from 'rxjs/operators';
import { select, dispatch } from '@angular-redux/store';
import { FormGroup, FormControl } from '@angular/forms';
import { Actions } from '../store';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { IonInput } from '@ionic/angular';

interface AddModeAuthoringContext { mode: 'add'; }
interface EditModeAuthoringContext { mode: 'edit'; todoId: string; }
type AuthoringContext = AddModeAuthoringContext | EditModeAuthoringContext;

@Component({
  selector: 'app-author',
  templateUrl: './author.page.html',
  styleUrls: ['./author.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorPage implements OnInit, AfterContentInit, OnDestroy {

  constructor(private location: Location, private route: ActivatedRoute) { }
  routeSubscription: Subscription;

  @select() list$: Observable<TodoItem[]>;
  authoringContext$: BehaviorSubject<AuthoringContext> = new BehaviorSubject<AuthoringContext>({ mode: 'add' });

  contextOptions$: Observable<string[]>;
  projectOptions$: Observable<string[]>;

  isBodyInputFocused$ = new BehaviorSubject<boolean>(false);

  todoForm = new FormGroup({
    body: new FormControl(''),
    priority: new FormControl('A'),
    contexts: new FormControl([]),
    projects: new FormControl([]),
    dueDate: new FormControl(null)
  });

  @ViewChild('todoBody') todoBodyInput: IonInput;
  private didFocusOnShow = false;
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.routeSubscription = combineLatest(this.list$, this.route.paramMap).subscribe(([list, pm]) => {
      const nextContext: AuthoringContext = pm.has('todoId') ? { mode: 'edit', todoId: pm.get('todoId') } : { mode: 'add' };
      if (nextContext.mode === 'edit') {
        const selectedTodo = list.find(t => t.id === nextContext.todoId);
        this.todoForm.setValue({
          body: selectedTodo.body,
          priority: selectedTodo.priority,
          contexts: selectedTodo.contexts,
          projects: selectedTodo.projects,
          dueDate: selectedTodo.dueDate,
        });
      } else {
        this.todoForm.patchValue({
          body: ''
        });
      }
      this.authoringContext$.next(nextContext);
    });

    this.contextOptions$ = this.list$.pipe(
      map(items => TagUtil.aggregateContexts(items, false)),
      map(todoTags => todoTags.map(t => t.name))
    );
    this.projectOptions$ = this.list$.pipe(
      map(items => TagUtil.aggregateProjects(items, false)),
      map(todoTags => todoTags.map(t => t.name))
    );
  }

  onClickSaveAndClose() {
    this.saveTodoFromFormData();
    this.location.back();
  }

  onClickSaveAndNew() {
    this.saveTodoFromFormData();
    this.focusBodyInput();
  }

  private saveTodoFromFormData() {
    // gather form data and dispatch

    const formData = this.todoForm.value;

    if (!formData.body) {
      this.focusBodyInput();
      return;
    }

    const authoringContext = this.authoringContext$.getValue();

    const todo = TodoItem.build({
      body: formData.body,
      priority: formData.priority,
      contexts: formData.contexts,
      projects: formData.projects,
      id: authoringContext.mode === 'edit' ? authoringContext.todoId : null
    });

    this.dispatchAddTodo(todo);

    this.todoForm.patchValue({
      body: ''
    });
  }

  ngAfterContentInit(): void {
    if (!this.didFocusOnShow) {
      this.didFocusOnShow = true;
      setTimeout(() =>
        this.focusBodyInput(), 500);
    }
  }

  focusBodyInput() {
    this.todoBodyInput.setFocus();
  }

  blurBodyInput() {
    this.todoBodyInput.getInputElement().then(el =>
      el.blur());
  }

  onBodyInputEnterPress(event) {
    this.blurBodyInput();
    // TODO focus the range
  }

  onBodyInputFocus(event) {
    this.isBodyInputFocused$.next(true);
  }

  onBodyInputBlur() {
    this.isBodyInputFocused$.next(false);
  }

  onPrioritySelectFocus() {
    console.log('onPrioritySelectFocus');
  }

  @dispatch()
  dispatchAddTodo(todo: TodoItem) {
    return Actions.mutateTodos({
      ops: [
        {
          type: TodoOperationType.UPSERT_TODO,
          todo: todo
        }]
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);
    const component = this.route.snapshot.component;

    if (event.key === 'Escape') {
      if (component === AuthorPage) {
        // console.log('Closing author screen because Escape');
        this.location.back();
      }
    }
  }
}
