<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Settings</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <form [connect]="['user', 'settings']">
    <ion-card>
      <ion-card-header>
        <ion-card-title>File Paths</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <ion-list>
          <ion-item>
            <ion-label>Todo File Path</ion-label>
            <ion-input name="todoFilePath" ngControl ngModel></ion-input>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-card-title>Beta Features</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <ion-list>
          <ion-item>
            <ion-label>Virtual Scrolling</ion-label>
            <ion-toggle name="useVirtualScroll" ngControl ngModel></ion-toggle>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>

  </form>
</ion-content>