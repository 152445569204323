import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { select } from '@angular-redux/store';
import { AppliedTodoOperationSet } from '../store/model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-log',
  templateUrl: './log.page.html',
  styleUrls: ['./log.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogPage implements OnInit {

  @select(['synchronization', 'inProgress'])
  synchronizationInProgress$: Observable<boolean>;

  @select('history')
  history$: AppliedTodoOperationSet[];

  constructor() { }

  ngOnInit() {
  }

  getLocalRevisionId(item: AppliedTodoOperationSet) {
    return item.localRevisionId;
  }
}
