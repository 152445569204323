import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { LogPage } from './log.page';
import { SyncStatusComponent } from './sync-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule
  ],
  declarations: [LogPage, SyncStatusComponent],
  exports: [SyncStatusComponent]
})
export class LogPageModule { }
