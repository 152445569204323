<ion-split-pane contentId="menu-content" when="md">
  <ion-menu contentId="menu-content" menuId="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>txtodo</ion-title>
        <ion-buttons slot="end">
          <ion-button slot="end" [routerLink]="['/settings']" routerDirection="forward">
            <ion-icon name="options"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-side-menu></app-side-menu>
      <ion-button (click)="logout()" color="light">Logout</ion-button>
    </ion-content>
  </ion-menu>

  <app-list class="ion-page" id="menu-content"></app-list>

</ion-split-pane>