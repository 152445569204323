import { Component, ChangeDetectionStrategy } from '@angular/core';
import { select } from '@angular-redux/store';
import { SynchronizationState, AppliedTodoOperationSet } from '../store/model';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

interface SyncInfo {
  isWriting: boolean;
}

@Component({
  selector: 'app-sync-status',
  styles: [`
  `],
  template: `
  <ng-template [ngIf]="isWriting$ | async" [ngIfElse]="isNotWriting">
    <ion-label>Writing local changes to server</ion-label>
  </ng-template>

  <ng-template #isNotWriting>
    <ion-label>Checking server for changes</ion-label>
  </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncStatusComponent {

  @select(['synchronization'])
  synchronization$: Observable<SynchronizationState>;

  @select(['history'])
  history$: Observable<AppliedTodoOperationSet[]>;

  isWriting$: Observable<boolean>;

  constructor() {
    this.isWriting$ = this.synchronization$.pipe(
      map(synchronization => !!synchronization.remoteRevisionId)
    );
  }
}
