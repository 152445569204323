import uuidv4 from 'uuid/v4';

import { TodoItem, TodoOperation } from '../lib/todotxtformat';
import { createAction, ActionsUnion, ActionsOfType } from './action-util';

export enum ActionTypes {
  STARTUP = 'STARTUP',

  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',

  NAVIGATE = '@angular-redux/router::UPDATE_LOCATION',

  MUTATE = 'MUTATE',

  SYNC_BEGIN = 'SYNC_BEGIN',
  SYNC_WORK = 'SYNC_WORK',
  SYNC_COMPLETE = 'SYNC_COMPLETE',
  SYNC_FAIL = 'SYNC_FAIL',

  SET_LAST_USED_FILTER = 'SET_LAST_USED_FILTER'

}
export const Actions = {
  startup: () => createAction(ActionTypes.STARTUP),

  setUser: (payload: {
    token: string;
  }) => createAction(ActionTypes.SET_USER, payload),

  logout: () => createAction(ActionTypes.LOGOUT),

  navigate: (payload: string) => createAction(ActionTypes.NAVIGATE, payload),

  mutateTodos: (payload: {
    ops: TodoOperation[];
  }) => createAction(ActionTypes.MUTATE, payload),

  mutateTodosOp: (op: TodoOperation) => Actions.mutateTodos({ ops: [op] }),

  syncBegin: (payload?: {
    skipIfSyncedSinceMs?: number,
    syncId?: string
  }) => {
    payload = payload || {};
    return createAction(ActionTypes.SYNC_BEGIN, { ...(payload), syncId: payload.syncId || <string>uuidv4() });
  },

  syncWork: (payload: {
    syncId: string,
  }) => createAction(ActionTypes.SYNC_WORK, payload),

  syncComplete: (payload: {
    syncId: string;
    todoFileContent: string;
    todos: TodoItem[];
    localRevisionId: string;
    remoteRevisionId: string;
    hadConflict: boolean;
    hadUnresolvableConflict: boolean;
  }) => createAction(ActionTypes.SYNC_COMPLETE, payload),

  syncFail: (payload: {
    syncId: string,
  }) => createAction(ActionTypes.SYNC_FAIL, payload),

  setLastUsedFilter: (payload: {
    route: string;
  }) => createAction(ActionTypes.SET_LAST_USED_FILTER, payload)
};
export type Actions = ActionsUnion<typeof Actions>;

export type ActionOfType<ActionType extends ActionTypes> = ActionsOfType<Actions, ActionType>;
