import { Component, ChangeDetectionStrategy } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'list-more-popover',
  styles: [``],
  template: `
  <ion-list>
    <ion-item (click)="addToHomeScreen()" button="true" detail="false" lines="none">
      <ion-label>Add to home screen</ion-label>
    </ion-item>
    <ion-item (click)="navigateToLogPage()" button="true" detail="false" lines="none">
      <ion-label>Change log</ion-label>
    </ion-item>
  </ion-list>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMorePopoverComponent {
  constructor(
    private popoverController: PopoverController,
    private router: Router) {
  }

  navigateToLogPage() {
    this.router.navigate(['/log']);
    this.popoverController.dismiss();
  }

  addToHomeScreen() {
    console.log('addToHomeScreen clicked');
    const beforeinstallprompt = (window as any).beforeinstallprompt;
    if (beforeinstallprompt) {
      beforeinstallprompt.prompt();

      beforeinstallprompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          (window as any).beforeinstallprompt = null;
        });
    }
    this.popoverController.dismiss();
  }
}
