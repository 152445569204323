<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      {{title$ | async}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-spinner (click)="presentSyncPopover($event)" name="dots" slot="end" color="light"
        [ngClass]="{ syncing: syncing$ | async }">
      </ion-spinner>
      <ion-button (click)="presentMorePopover($event)">
        <ion-icon name="settings"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-dropdown" refreshingSpinner="crescent">
    </ion-refresher-content>
  </ion-refresher>

  <ng-template [ngIf]="useVirtualScroll$ | async" [ngIfElse]="nonVirtualScroll">
    <ion-virtual-scroll [items]="displayList$ | async" [headerFn]="getSectionDivider" [trackBy]="getTodoId"
      approxItemHeight="40px" class="compact">
      <ion-item-divider *virtualHeader="let header">
        <ion-label size=large>
          {{ header }}
        </ion-label>
      </ion-item-divider>
      <div *virtualItem="let item">
        <todo-list-item [item]="item"></todo-list-item>
      </div>
    </ion-virtual-scroll>
  </ng-template>

  <ng-template #nonVirtualScroll>
    <ion-list class="compact">
      <ng-container *ngFor="let group of displayListGrouped$ | async; trackBy:getGroupTitle">
        <ion-item-divider>
          <ion-label size=large>
            {{group.title}}
          </ion-label>
        </ion-item-divider>
        <todo-list-item [item]="item" *ngFor="let item of group.items; trackBy:getTodoId"></todo-list-item>
      </ng-container>
    </ion-list>
  </ng-template>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="openAddPage()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>