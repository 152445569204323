import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'list-sync-popover',
  styles: [``],
  template: `
  <ion-content padding><app-sync-status></app-sync-status></ion-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSyncPopoverComponent {
}
