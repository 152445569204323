<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Add todo</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <form [formGroup]="todoForm">
    <!-- <ion-item> -->
    <ion-label position="stacked" size="large">Todo</ion-label>
    <div class="body-input-container" [ngClass]="{ focused: isBodyInputFocused$ | async }">
      <ion-input #todoBody name="body" tabindex="0" formControlName="body" autofocus required
        (ionFocus)="onBodyInputFocus($event)" (ionBlur)="onBodyInputBlur()"
        (keydown.enter)="onBodyInputEnterPress($event)" autocapitalize="sentences">
      </ion-input>
    </div>
    <!-- </ion-item> -->

    <!-- <ion-item> -->
    <ion-label position="stacked">Priority</ion-label>
    <author-priority-select formControlName="priority" (focus)="onPrioritySelectFocus()">
    </author-priority-select>
    <!-- </ion-item> -->

    <hr />

    <ion-label position="stacked">Contexts</ion-label>
    <author-tag-multi-select tabindex="0" [options]="contextOptions$" formControlName="contexts">
    </author-tag-multi-select>

    <hr />

    <ion-label position="stacked">Projects</ion-label>
    <author-tag-multi-select tabindex="0" [options]="projectOptions$" formControlName="projects">
    </author-tag-multi-select>

    <ion-item>
      <ion-label position="floating">Due Date</ion-label>
      <ion-datetime tabindex="0" name="dueDate" displayFormat="YYYY-MM-DD" placeholder="Select Date"
        formControlName="dueDate">
      </ion-datetime>
    </ion-item>

  </form>

</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button expand="full" fill="outline" (click)="onClickSaveAndNew();">
          <ion-label>Save + New</ion-label>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button expand="full" (click)="onClickSaveAndClose();">
          <ion-label>Save + Close</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>