import { TodoItem } from './model';

export interface TodoTag {
  type: string;
  id: string;
  name: string;
  occurrences: number;
}

export class TagUtil {
  public static aggregateContexts(todos: TodoItem[], caseSensitive: Boolean = false): TodoTag[] {
    return TagUtil.sortTags(TagUtil.aggregateTags(todos, todo => todo.contexts, '@', caseSensitive));
  }

  public static aggregateProjects(todos: TodoItem[], caseSensitive: Boolean = false): TodoTag[] {
    return TagUtil.sortTags(TagUtil.aggregateTags(todos, todo => todo.projects, '+', caseSensitive));
  }

  public static aggregateTags(
    todos: TodoItem[],
    accessor: (TodoItem) => string[],
    type: string,
    caseSensitive: Boolean = false): TodoTag[] {
    let tags = new Map<string, TodoTag>();

    for (const todo of todos) {
      for (const tag of accessor(todo)) {
        const id = type + tag;
        if (tags.has(id)) {
          tags.get(id).occurrences++;
        } else {
          tags.set(id, { type: type, name: tag, id: id, occurrences: 1 });
        }
      }
    }

    if (!!caseSensitive) {
      tags = TagUtil._collapseDifferentCasing(tags);
    }

    const tagsArr = [];
    tags.forEach(tag => tagsArr.push(tag));

    return tagsArr;
  }

  public static filterTagByType(todoTags: TodoTag[], type: string) {
    const contexts = [];
    todoTags.forEach(tag => {
      if (tag.type === type) {
        contexts.push(tag);
      }
    });
    return contexts;
  }

  public static sortTags(tags: TodoTag[]): TodoTag[] {
    return tags.sort(TagUtil.compareTagsByOccurrences);
  }

  public static compareTagsByOccurrences = (tagA: TodoTag, tagB: TodoTag) => {
    return tagB.occurrences - tagA.occurrences;
  }

  private static _collapseDifferentCasing(tags: Map<string, TodoTag>): Map<string, TodoTag> {
    const groups: Map<string, TodoTag[]> = new Map<string, TodoTag[]>();

    tags.forEach(tag => {
      const caseInvariant = tag.id.toLowerCase();
      if (groups.has(caseInvariant)) {
        groups.get(caseInvariant).push(tag);
      } else {
        groups.set(caseInvariant, [tag]);
      }
    });

    const result = new Map<string, TodoTag>();
    groups.forEach(group => {
      const sorted = TagUtil.sortTags(group);
      const first = sorted[0];
      for (const rest of sorted.slice(1)) {
        first.occurrences += rest.occurrences;
      }
      result.set(first.id, first);
    });
    return result;
  }
}
