<ion-item-sliding>
  <ion-item lines="none" (click)="onItemClick($event)" button="true" detail="false">
    <!-- <ion-icon [name]="item.icon" slot="start"></ion-icon> -->
    <div (click)="onCheckboxClick($event)" class='checkbox-container'>
      <ion-checkbox slot="start" [checked]="item.isCompleted" #checkbox></ion-checkbox>
    </div>

    <ion-label text-wrap>
      {{item.body}}
    </ion-label>
    <ion-note slot="end" color="medium">
      <span *ngFor="let tag of item.projects">+{{tag}} </span>
    </ion-note>
    <!-- <div class="item-note" slot="end">
      <span *ngFor="let tag of item.projects">+{{tag}} </span>
    </div> -->
  </ion-item>
  <ion-item-options side="end">
    <ion-item-option color="light">
      <ion-icon name="trash" color="danger"></ion-icon>
      <ion-text color="danger">Delete</ion-text>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>