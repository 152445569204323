import { Component, Input, forwardRef, ViewChild, Output, EventEmitter, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'author-priority-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuthorPrioritySelectComponent),
      multi: true
    }
  ],
  styles: [`
  ion-range {
    --knob-size: 30px;
    --bar-height: 3px;
  }
  div.priority-select-container {
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    border-color: #FEFEFE;
  }
  div.priority-select-container.focused {
    border-color: var(--ion-color-primary);
  }`],
  template: `
  <div class="priority-select-container" [ngClass]="{ focused: isFocused$ | async }">
    <ion-range min="1" max="26" snaps="true" pin="true"
      name="priority" #priority (ionChange)="onChange()" (ionFocus)="onFocus()" (ionBlur)="onBlur()">
      <ion-label slot="start">A</ion-label>
      <ion-label slot="end">Z</ion-label>
    </ion-range></div>`
})
export class AuthorPrioritySelectComponent implements ControlValueAccessor {
  @ViewChild('priority') priorityRange;

  @Input()
  set value(value: string) {
    // TODO handle empty pri
    this.priorityRange.value = (value || 'A').toUpperCase().charCodeAt(0) - 64;
  }
  get value(): string {
    return String.fromCharCode(this.priorityRange.value + 64);
  }

  @Output()
  focus = new EventEmitter<any>();

  isFocused$ = new BehaviorSubject<boolean>(false);

  private _changeListener: any = null;
  private _blurListener: any = null;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._changeListener = fn;
  }
  registerOnTouched(fn: any): void {
    this._blurListener = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  onFocus() {
    this.isFocused$.next(true);
    this.focus.emit();
  }

  onBlur() {
    this.isFocused$.next(false);
  }

  onChange() {
    const changeListener = this._changeListener;
    if (changeListener) {
      changeListener(this.value);
    }
    const blurListener = this._blurListener;
    if (blurListener) {
      blurListener();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.isFocused$.value) { return; }
    if (event.keyCode >= 65 && event.keyCode < 90) {
      this.writeValue(String.fromCharCode(event.keyCode));
    }
    if (event.code === 'ArrowLeft') {
      this.writeValue(String.fromCharCode(Math.max(this.priorityRange.value - 1, 65)));
    }

    if (event.code === 'ArrowLeft') {
      this.writeValue(String.fromCharCode(Math.min(this.priorityRange.value + 1, 90)));
    }
  }
}
