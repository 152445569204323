/// <reference path="../../node_modules/@types/applicationinsights-js/index.d.ts" />

import { AppInsights } from './app-insights';

class Logger {
  verbose = (message: string) => this._traceTrace(message, 0);
  info = (message: string) => {
    this._traceTrace(message, 1);
    console.log(message);
  }
  warn = (message: string) => {
    this._traceTrace(message, 2);
    console.warn(message);
  }
  error = (message: string) => {
    this._traceTrace(message, 3);
    console.error(message);
  }
  critical = (message: string) => {
    this._traceTrace(message, 4);
    console.error(message);
  }

  private _traceTrace(message: string, level: AI.SeverityLevel) {
    AppInsights.trackTrace(message, {}, level);
  }
}

export const logger = new Logger();
