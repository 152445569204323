import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserSettingsState } from '../store/model';
import { select } from '@angular-redux/store';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPage implements OnInit {

  // @select(['user', 'settings'])
  // userSettings$: BehaviorSubject<UserSettingsState>;

  constructor() { }

  ngOnInit() {
  }

}
